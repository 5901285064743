// Morph
// update an existing DOM structure by merging with a new one

import { StreamActions } from '@hotwired/turbo'
import { Idiomorph } from 'idiomorph/dist/idiomorph.esm'

export function setupMorph() {
  StreamActions.morph = function () {
    const fromElement = this.targetElements[0]
    const toHTML = this.templateContent

    // Perform the morph operation
    Idiomorph.morph(fromElement, toHTML, {
      callbacks: {
        beforeNodeMorphed: function (node) {
          if (document.activeElement === node && node.dataset.noMorphIfActive === 'true') {
            // Check node is:
            // 1. Currently active element
            // 2. Has data-no-morph-if-active="true"
            return false // skip morph
          }
        }
      }
    })
  }
}
