import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ['onlyValidateField', 'submitButton']
  static values = {
    modelChanged: Boolean
  }

  static debounces = [
    {
      name: 'validate',
      wait: 500
    }
  ]

  connect() {
    useDebounce(this)
    this.removeUnloadEvent()
  }

  modelChangedValueChanged() {
    if (this.modelChangedValue) {
      this.addUnloadEvent() // Notify the user that changes have been made using a browser message
    } else {
      this.removeUnloadEvent()
    }
  }

  addUnloadEvent() {
    window.onbeforeunload = (event) => {
      event.preventDefault()
      event.returnValue = '' // required by Chrome
    }
  }

  removeUnloadEvent() {
    window.onbeforeunload = null
  }

  submit(event = null) {
    if (event && this.hasSubmitButtonTarget) {
      event.preventDefault()

      // check HTML5 form validations and tell submit button to start loading if they pass
      if (this.element.checkValidity()) {
        this.submitButtonTarget.dispatchEvent(new Event('formSubmitting'))
      } else {
        this.element.reportValidity() // show native validation messages
        return
      }
    }

    this.element.requestSubmit()
  }

  validate() {
    // this function is debounced via the "debounces" list above
    this.submitWithOnlyValidate()
  }

  fastValidate() {
    // this function isn't debounced
    this.submitWithOnlyValidate()
  }

  submitWithOnlyValidate() {
    // Submit the form for validations and no persistence

    // 1. Set the hidden form field "only_validate" to true (only_validate=true in the Rails controller)
    this.onlyValidateFieldTarget.value = true

    // 2. Submit the form
    this.submit()
  }
}
