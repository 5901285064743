// Redirect
// redirect/visit the url given by the backend

// idea taken from - https://www.ducktypelabs.com/turbo-break-out-and-redirect
// hopefully future versions of Turbo will include redirect handling

import { StreamActions } from '@hotwired/turbo'

export function setupRedirect() {
  StreamActions.redirect = function () {
    // eslint-disable-next-line no-undef
    window.location.replace(this.target)
  }
}
